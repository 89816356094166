@import "../../../variables.scss";

.Reviews {
    padding-top: 6.25em;
    background-color: $headerColor;
    .wrapper {
        padding: 6.875em 0;
        @include MaxWidth;
        h2 {
            @include titleH2;
        }
        .Yandex {
            margin-top: 5.625em;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 800px;
            overflow: hidden;
            position: relative;
            .Iframe {
                width: 47em;
                height: 100%;
                border: 1px solid #e6e6e6;
                border-radius: 8px;
                box-sizing: border-box;
            }
        }
    }
}
