@import "../../../variables.scss";

.FooterMobile {
    background-color: $headerModalWindow;
    .wrapper {
        padding-top: 8.25em;
        padding-bottom: 8.25em;
        @include MaxWidth;
        .container {
            .row:nth-of-type(1) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2.167em;
                .column:nth-of-type(1) {
                    .logo {
                        width: 10.9375em;
                    }
                }
                .column:nth-of-type(2) {
                    p {
                        font-size: 1em;
                        color: $headerText;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }
            }
            .row:nth-of-type(2) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2.167em;
                .column:nth-of-type(1) {
                    font-size: 1em;
                    .box_contacts {
                        margin-bottom: 1.25em;
                        img {
                            width: 1.5625em;
                            margin-right: 0.417em;
                        }
                        a {
                            text-decoration: none;
                            color: $headerTextSecondary;
                            font-weight: 500;
                        }
                    }
                    .copyright {
                        color: $headerText;
                        font-weight: 500;
                    }
                }
                .column:nth-of-type(2) {
                    flex-basis: 15em;
                    .box {
                        display: flex;
                        align-items: center;
                        &:nth-of-type(1) {
                            margin-bottom: 1.25em;
                        }
                        span {
                            font-size: 1em;
                            color: $headerText;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                        img {
                            width: 1.7em;
                            &:nth-of-type(1) {
                                margin-right: 0.417em;
                            }
                        }
                    }
                }
            }
            .row:nth-of-type(3) {
                p {
                    font-size: 1em;
                    color: $headerText;
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
        }
    }
}
