@import "../../../variables.scss";

.Contacts {
    background-color: $headerColor;
    .wrapper {
        padding-top: 8.25em;
        padding-bottom: 8.25em;
        @include MaxWidth;
        .container {
            .row:nth-of-type(1) {
                margin-bottom: 5.625em;
                h2 {
                    font-size: 2.375em;
                    color: $headerText;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
            .row:nth-of-type(2) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                @media (max-width: 767px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-row-gap: 1.875rem;
                }
                .column:nth-of-type(1) {
                    @media (max-width: 767px) {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }
                    .box {
                        display: flex;
                        align-items: center;
                        &:nth-of-type(1) {
                            margin-bottom: 1.875em;
                        }
                        img {
                            width: 3.4375em;
                            margin-right: 1.875em;
                        }
                        a {
                            font-size: 1.5em;
                            color: $headerTextSecondary;
                            font-weight: 500;
                            text-decoration: none;
                            &:hover {
                                color: $headerText;
                            }
                        }
                    }
                }
                .column:nth-child(2),
                .column:nth-child(3) {
                    text-decoration: none;
                    &:hover {
                        p {
                            color: $headerTextSecondary;
                        }
                    }
                    img {
                        width: 3.4375em;
                        margin-bottom: 1em;
                    }
                    p {
                        font-size: 1.5em;
                        color: $headerText;
                        font-weight: 500;
                        @media (max-width: 430px) {
                            width: 90%;
                        }
                    }
                }
                .column:nth-child(2) {
                    @media (max-width: 767px) {
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }
                    @media (min-width: 768px) {
                        justify-self: center;
                        text-align: center;
                    }
                }
                .column:nth-child(3) {
                    @media (max-width: 767px) {
                        grid-column-start: 2;
                        grid-column-end: 3;
                    }
                    @media (min-width: 768px) {
                        justify-self: self-end;
                        text-align: center;
                    }
                }
            }
        }
    }
}
