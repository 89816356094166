// Переменные
$headerColor: #222222;
$headerText: #ffffff;
$headerTextSecondary: #ed5f00;
$headerModalWindow: #060606;


.BurgerMenu {
    background-color: $headerModalWindow;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    position: fixed;
    top: 0;
    right: 0;
    padding: 6.25em 3.75em;
    @media (max-width: 576px) {
        font-size: 12px;
        padding: 4em 2.5em;
    }
    .column:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        ul {
            list-style-type: none;
            li {
                a {
                    color: $headerText;
                    font-size: 1.125em;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                }
                &:nth-of-type(n) {
                    margin-bottom: 1.5625em;
                }
                &:nth-last-of-type(1) {
                    margin: 0;
                }
            }
        }
    }
    .column:nth-child(2) {
        justify-self: end;
        margin-top: 0.7em;
        display: block;
        height: 2em;
        width: 2em;
        margin-right: -0.6em;
        span {
            width: 1.625em;
            height: 0.25em;
            background-color: $headerTextSecondary;
            display: block;
            &:nth-of-type(1) {
                transform: rotate(45deg);
            }
            &:nth-of-type(2) {
                transform: rotate(-45deg);
                margin-left: -0.04em;
                margin-right: -0.5em;
                margin-top: -0.29em;
            }
        }
    }
    .column:nth-child(3) {
        @media (max-width: 576px) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 3;
            grid-row-end: 4;
            padding-top: 4em;
        }
        align-self: end;
        .column_box:nth-of-type(1) {
            margin-bottom: 1.5em;
        }
        .column_box:nth-of-type(1),
        .column_box:nth-of-type(2) {
            img {
                width: 1.5625em;
                margin-right: 0.625em;
            }
            a {
                color: $headerTextSecondary;
                font-size: 1.125em;
                font-weight: 600;
                text-decoration: none;
                &:hover {
                    color: $headerText;
                }
            }
        }
    }
}