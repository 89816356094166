@import "../../../variables.scss";

.Evacuate {
    background-color: $headerColor;
    background-image: url(../../../../img/Main/sectionMain/line.svg);
    background-repeat: repeat-x;
    background-position-y: 100%;
    background-position-x: 50%;
    .wrapper {
        @include MaxWidth;
        @media (max-width: 1140px) {
            font-size: 14px;
        }
        @media (max-width: 991px) {
            font-size: 11px;
        }
        padding-top: 8.25em;
        padding-bottom: 8.25em;
        .row {
            &:nth-of-type(1) {
                margin-bottom: 5.625em;
                @media (max-width: 576px) {
                    margin-bottom: 3.125em;
                }
                h3 {
                    font-size: 2.375em;
                    color: $headerText;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
            &:nth-of-type(2) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                @media (max-width: 991px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (max-width: 576px) {
                    grid-row-gap: 3em;
                }
                grid-row-gap: 6.625em;
                align-items: end;
                justify-items: center;
                text-align: center;
                .column {
                    @media (max-width: 767px) {
                        font-size: 10px;
                    }
                    @media (mix-width: 992px) {
                        &:nth-of-type(1),
                        &:nth-of-type(4) {
                            justify-self: self-start;
                        }
                        &:nth-of-type(3),
                        &:nth-of-type(6) {
                            justify-self: self-end;
                        }
                    }
                    h4 {
                        font-size: 1.5em;
                        color: $headerText;
                        font-weight: 500;
                        margin-top: 2em;
                    }
                    img {
                        @media (max-width: 576px) {
                            font-size: 7px;
                        }
                        @media (max-width: 374px) {
                            font-size: 6px;
                        }
                    }
                    &:nth-of-type(1),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(6) {
                        img {
                            width: 21.875em;
                        }
                    }
                    &:nth-of-type(2) {
                        img {
                            width: 10.9375em;
                        }
                    }
                    &:nth-of-type(5) {
                        img {
                            width: 13.75em;
                        }
                    }
                }
            }
        }
    }
}
