@import "../../../variables.scss";

.FooterDesktopTablet {
    background-color: $headerModalWindow;
    .wrapper {
        @include MaxWidth;
        .container {
            padding-top: 8.25em;
            padding-bottom: 8.25em;
            display: flex;
            justify-content: space-between;
            .column:nth-of-type(1) {
                .Logo {
                    width: 10.9375em;
                    margin-bottom: 1.875em;
                }
                .box {
                    img {
                        width: 1.5625em;
                        margin-right: 0.625em;
                    }
                    a {
                        font-size: 1.05em;
                        color: $headerTextSecondary;
                        font-weight: 500;
                        text-decoration: none;
                        &:hover {
                            color: $headerText;
                        }
                    }
                    .box_contacts {
                        display: flex;
                        align-items: center;
                        &:nth-of-type(1) {
                            margin-bottom: 1em;
                        }
                    }
                }
                .copyright {
                    font-size: 1.25em;
                    color: $headerText;
                    margin-top: 0.4375em;
                    display: flex;
                    align-items: center;
                    span:nth-of-type(1) {
                        font-size: 1.3em;
                        margin-right: 0.125em;
                    }
                }
            }
            .column:nth-of-type(2) {
                font-size: 1.05em;
                color: $headerText;
                font-weight: 500;
                text-transform: uppercase;
                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
                p:nth-of-type(1) {
                    margin-bottom: 1.7em;
                }
                .box {
                    &:nth-of-type(1) {
                        margin-bottom: 1.5em;
                    }
                    &:nth-of-type(2) {
                        margin-bottom: 1.65em;
                    }
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        display: flex;
                        align-items: center;
                        a {
                            img {
                                width: 2em;
                                height: 100%;
                            }
                            &:nth-of-type(1) {
                                margin-left: 1em;
                            }
                            &:nth-of-type(2) {
                                margin-left: 0.625em;
                            }
                        }
                    }
                }
            }
        }
    }
}
