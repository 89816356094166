@import "../../../variables.scss";

.MassSection {
    background-color: $headerColor;
    background-image: url(../../../../img/Main/SectionMass/line.svg);
    @media (max-width: 768px) {
        background-image: url(../../../../img/Main/SectionMass/lineTablet.png);
    }
    background-repeat: repeat-x;
    background-position-y: 100%;
    background-position-x: 50%;
    .wrapper {
        @include MaxWidth;
        .container {
            padding-top: 8.25em;
            padding-bottom: 8.25em;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .column:nth-of-type(1) {
                h2 {
                    text-transform: uppercase;
                    color: $headerText;
                    font-weight: 700;
                    font-size: 2.375em;
                    margin-bottom: 2.7em;
                }
                h3 {
                    font-size: 1.5em;
                    font-weight: 500;
                    margin-bottom: 1.67em;
                    span:nth-of-type(1) {
                        color: $headerText;
                    }
                    span:nth-of-type(2) {
                        color: $headerTextSecondary;
                    }
                }
                .box {
                    display: flex;
                    margin-top: 1.9375em;
                    img {
                        width: 1.25em;
                        &:nth-of-type(1) {
                            align-self: flex-end;
                        }
                        &:nth-of-type(2) {
                            align-self: flex-start;
                        }
                    }
                    p {
                        margin: 0.5em 0;
                        font-size: 1.25em;
                        color: $headerText;
                        font-weight: 300;
                        flex-basis: 19em;
                    }
                }
            }
            .column:nth-of-type(2) {
                img {
                    width: 33em;
                    @media (max-width: 767px) {
                        width: 28em;
                        margin-top: 1em;
                    }
                }
            }
        }
    }
}
