@import "../../../variables.scss";

.WindowModal {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.54);
    top: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    @media (max-width: 992px) {
        font-size: 12px;
    }
    @media (max-width: 430px) {
        font-size: 10px;
    }
    .container {
        border: 0.3125em solid $headerTextSecondary;
        width: 57.5em;
        @media (max-width: 767px) {
            width: 30.5em;
        }
        background-color: $headerModalWindow;
        display: flex;
        justify-content: space-between;
        padding: 4.375em 3.125em;
        box-sizing: border-box;
        @media (max-width: 767px) {
            text-align: center;
        }
        @media (min-width: 768px) {
            background-image: url(../../../../img/Main/sectionMain/lineModal.svg);
            background-repeat: no-repeat;
            background-position-x: 38em;
            background-position-y: -1em;
        }
        .column:nth-child(1) {
            h2 {
                font-size: 2.4em;
                color: $headerText;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 0.78125em;
                @media (max-width: 767px) {
                    margin-bottom: 0.52em;
                }
            }
            .manual {
                font-size: 1.5em;
                font-weight: 500;
                color: $headerText;
                margin-bottom: 3.125em;
                @media (max-width: 767px) {
                    margin-bottom: 2em;
                }
            }
            .number_one {
                margin-bottom: 1em;
                @media (max-width: 767px) {
                    margin-bottom: 1.1em;
                }
                span:nth-child(1) {
                    margin-right: 1.25em;
                    @media (max-width: 767px) {
                        margin-right: 0;
                        margin-bottom: 0.7em;
                    }
                }
            }
            .number_to {
                margin-bottom: 2.083em;
                @media (max-width: 767px) {
                    margin-bottom: 2em;
                }
                span:nth-child(1) {
                    margin-right: 1.5em;
                    @media (max-width: 767px) {
                        margin-right: 0;
                        margin-bottom: 0.7em;
                    }
                }
            }
            .number_one,
            .number_to {
                display: flex;
                text-decoration: none;
                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                }
                font-size: 1.5em;
                font-weight: 500;
                &:hover {
                    span:nth-child(2) {
                        color: $headerText;
                    }
                }
                span:nth-child(1) {
                    color: $headerText;
                }
                span:nth-child(2) {
                    color: $headerTextSecondary;
                }
            }
            .messenger {
                display: flex;
                justify-content: space-between;
                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                }
                .messenger_auto,
                .messenger_truck {
                    font-size: 1.5em;
                    color: $headerText;
                    text-align: center;
                    text-decoration: none;
                    img {
                        width: 2.375em;
                        margin-bottom: 0.1em;
                    }
                    &:hover {
                        color: $headerTextSecondary;
                    }
                }
                .messenger_auto {
                    @media (max-width: 767px) {
                        margin-bottom: 2em;
                    }
                }
            }
        }
        .column:nth-child(2) {
            justify-self: end;
            margin-top: 1em;
            @media (max-width:767px) {
                margin-top: 1.2em;
            }
            display: block;
            height: 2em;
            width: 2em;
            margin-right: -0.6em;
            cursor: pointer;
            &:hover {
                span {
                    background-color: $headerText;
                }
            }
            span {
                width: 1.625em;
                height: 0.25em;
                background-color: $headerTextSecondary;

                display: block;
                &:nth-of-type(1) {
                    transform: rotate(45deg);
                }
                &:nth-of-type(2) {
                    transform: rotate(-45deg);
                    margin-top: -0.24em;
                }
            }
        }
    }
}
