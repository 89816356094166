// Переменные
$headerColor: #222222;
$headerText: #ffffff;
$headerTextSecondary: #ed5f00;
$headerModalWindow: #060606;

// Обнуление
body,
ul {
    margin: 0;
    padding: 0;
}
// Подключение шрифта
body {
    font-family: "Montserrat", sans-serif;
}

.HeaderMobile {
    @media (max-width: 576px) {
        font-size: 20px;
    }
    @media (max-width: 430px) {
        font-size: 16px;
    }
    background-color: $headerColor;
    position: fixed;
    width: 100%;
    z-index: 10;
    .wrapper {
        padding: 1em 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .column:nth-child(1) {
            background-image: url(../../../../img/Header/logo.svg);
            width: 6.25em;
            display: block;
            height: 2.25em;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .column:nth-child(2) {
            img {
                width: 0.875em;
                margin-right: 0.4375em;
            }
            a {
                font-size: 0.7em;
                text-decoration: none;
                color: $headerTextSecondary;
                font-weight: 500;
                &:hover {
                    color: $headerText;
                }
            }
        }
        .Burger {
            span {
                width: 1.5625em;
                height: 0.1875em;
                background-color: $headerText;
                display: block;
                &:nth-of-type(1),
                &:nth-of-type(2) {
                    margin-bottom: 0.4375em;
                }
                &:nth-of-type(2) {
                    margin-left: 0.625em;
                }
            }
        }
    }
}