@import "../../../variables.scss";

.Prices {
    padding: 6.25em 0;
    background-color: $headerColor;
    .wrapper {
        @include MaxWidth;
        .row:nth-of-type(1) {
            margin: 5.625em 0;
            h2 {
                font-size: 2.375em;
                font-weight: 700;
                color: $headerText;
                text-transform: uppercase;
            }
        }
        .row:nth-of-type(2) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @media (max-width: 767px) {
                grid-template-columns: 1fr;
            }
            grid-column-gap: 6.25em;
            grid-row-gap: 5.625em;
            .column {
                &:nth-of-type(8) {
                    @media (min-width: 768px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                    .headerList:nth-of-type(2) {
                        margin-top: 3.75em;
                    }
                }
                .headerList {
                    font-size: 1.5em;
                    color: $headerTextSecondary;
                    font-weight: 500;
                    margin-bottom: 2.75em;
                }
                .column_grid {
                    display: grid;
                    grid-template-columns: 5fr 5fr;
                    grid-column-gap: 2.5em;
                    grid-row-gap: 1.5625em;
                    font-size: 1.25em;
                    color: $headerText;
                    font-weight: 400;
                }
                p {
                    margin-top: 1.5em;
                    font-size: 1.25em;
                    font-weight: 400;
                    color: $headerText;
                }
                .button {
                    display: flex;
                    @media (max-width: 767px) {
                        margin-top: 4.167em;
                    }
                    a {
                        cursor: pointer;
                        font-size: 1.5em;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: $headerText;
                        font-weight: 600;
                        padding: 0.75em 1.371em;
                        background-color: $headerTextSecondary;
                        position: relative;
                        z-index: 1;
                        &:hover {
                            transform: translate(0.27em, 0.27em);
                            transition-duration: 300ms;
                        }
                    }
                    .buttonBorder {
                        border: 0.1875em solid $headerText;
                        padding: 1.08em 9.73em;
                        margin-left: -19.8em;
                        transform: translate(0.4em, 0.4em);
                    }
                }
            }
        }
    }
}
